/* Footer.css */
.footer {
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
    background-color: papayawhip; /* Match the global background color */
    color: #000000; /* Match the global text color */
    border-top: 1px solid #bf003a;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.left,
.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: grey;
}

.right {
    align-items: flex-end;
}

.center {
    text-align: center;
    margin-top: 20px;
    font-size: 10px;
}

.logo {
    height: 30px;
    margin-bottom: 10px;
}

a {
    color: grey; /* Set link color to match the text color */
    text-decoration: none;
    transition: color 0.3s;
}

a:hover {
    color: #ff6b6b; /* Change link color on hover */
}

@media (max-width: 768px) {
    .footer {
        width: 95%;
    }

    .topSection {
        flex-direction: column;
        align-items: center;
    }

    .right {
        align-items: center;
        margin-top: 20px;
    }
}
