/* src/pages/LoginPage.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.login-container {
    text-align: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    /*margin-top: 15%; !* Adjust to center the container at around 25% from the top *!*/
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: black;
}

.subtitle {
    color: grey;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #4285f4;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #357ae8;
}

.terms-message {
    margin-top: 20px;
    font-size: 7px;
    color: #777;
}

.terms-message a {
    color: #4285f4;
    text-decoration: none;
}

.terms-message a:hover {
    text-decoration: underline;
}
