/* LandingPage.css */
.landing-page {
    background-color: papayawhip; /* Background color for the entire page */
    color: #000000; /* Set text color */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif; /* Apply Inter font to this component */
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%; /* Width of the content on the page */
    margin: 0 auto;
}

.text-section {
    flex: 1;
    margin-right: 20px; /* Add margin to the right of the text section */
}

.image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-left: 20px; /* Add margin to the left of the image section */
}

.preview-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: Add some rounded corners to the image */
}

h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

p {
    font-size: 1.5em;
    margin-bottom: 40px;
}

.download-button {
    display: flex; /* Align the icon with the text */
    align-items: center;
    padding: 8px 16px; /* Reduce padding to make the button less wide */
    font-size: 1.2em;
    font-weight: bold;
    background-color: black; /* Button background */
    color: papayawhip; /* Button text color */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    width: 50%; /* Ensure button width is auto, not full width */
    height: 50px;
}

.download-button .gmail-icon {
    width: 20px; /* Set a fixed width for the icon */
    height: 20px; /* Set a fixed height for the icon */
    margin-right: 8px; /* Add some space between the icon and the text */
}

.download-button:hover {
    background-color: #ff6b6b; /* Button hover background */
    color: white; /* Button hover text color */
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        text-align: center;
        width: 95%; /* Make it more responsive */
    }

    .text-section,
    .image-section {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0; /* Remove margin for smaller screens */
        margin-right: 0; /* Remove margin for smaller screens */
    }

    .download-button {
        width: 80%; /* Adjust the width to fit better on mobile screens */
        margin: 0 auto; /* Center the button horizontally */
    }

    h1 {
        font-size: 2.5em;
    }

    p {
        font-size: 1.2em;
    }
}
