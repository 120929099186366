/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap'); /* Import the Inter font */

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif; /* Apply Inter font globally */
  background-color: papayawhip; /* Background color for the entire page */
  color: #000000; /* Text color */
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}
