/* Header.css */
.header {
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: papayawhip; /* Match the global background color */
    color: #bf003a; /* Match the global text color */
}

.logo {
    height: 40px;
}

@media (max-width: 768px) {
    .header {
        width: 95%;
    }
}
